
import { getMyReviewList } from '@/api/review';
import { likeReview } from '@/api/reviewLike';
import { UserModule } from '@/store/modules/user';
import { Component, Vue } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getReviewList();
  }

  private listQuery = {
    page: 1,
    size: 10,
  }

  private totalElements = 0;

  private reviewList = [];

  private loading = true;

  private getReviewList() {
    this.loading = true;
    getMyReviewList(this.listQuery).then((res) => {
      this.reviewList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.loading = false;
    });
  }

  private handleClickLike(review: any) {
    if (UserModule.isLogin) {
      likeReview(review.idx).then(() => {
        review.likeStatus = !review.likeStatus;
        if (review.likeStatus) review.likeCount += 1;
        else review.likeCount -= 1;
      });
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
      this.$router.push({ name: 'Login' });
    }
  }

  private handleDetail(review: any) {
    this.$router.push({ name: 'ReviewEditForm', params: { idx: review.idx } });
  }
}
